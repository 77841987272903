<template>
  <div class="logincontent">
    <div class="loginbox">
      <div class="welcometxt">忘记密码</div>
      <div class="inputbox marginT80">
        <van-field label-align="left" label-width="60" v-model="account" label="账号" placeholder="请输入账号" />
      </div>
      <div class="inputbox marginT44">
        <van-field
          label-align="left"
          label-width="60"
          v-model="password"
          label="新密码"
          placeholder="请输入密码"
          type="password"
        />
      </div>
      <div class="inputbox marginT44">
        <van-field
          label-align="left"
          label-width="60"
          v-model="repwd"
          label="确认密码"
          placeholder="请输入密码"
          type="password"
        />
      </div>
      <div class="inputbox marginT44">
        <van-field label-align="left" label-width="60" v-model="vercode" label="验证码" placeholder="请输入验证码">
          <template #button>
            <div class="verode" @click="getVercode">{{ codemessage }}</div>
          </template>
        </van-field>
      </div>
      <div class="btn" @click="clickAlert">修改</div>
      <div class="forget" @click="clickLogin">登录</div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getCode, resetPwd } from "@/request/storeapi";
export default {
  data() {
    return {
      account: "",
      password: "",
      repwd: "",
      vercode: "",
      codemessage: "获取验证码",
      disabled: false,
    };
  },
  methods: {
    requestresetPwd() {
      const params = {
        user_nickname: this.account,
        user_pass: this.repwd,
        verification_code: this.vercode,
      };
      resetPwd(params).then((res) => {
        let suc = res.success;
        if (suc) {
          Toast(res.msg);
          this.$router.go(-1);
        } else {
          Toast(res.msg);
        }
      });
    },
    requestGetCode() {
      if (this.disabled) {
        return;
      }
      if (this.account.length === 0) {
        Toast("请输入账号");
        return;
      }
      const params = {
        user_nickname: this.account,
      };
      getCode(params).then((res) => {
        if (res.code == 200) {
          this.disabled = true;
          Toast("发送成功");
          this.codemessage = 60;
          this.timer();
        } else {
          Toast("今日发送已达上限");
        }
      });
    },
    timer() {
      let _this = this;
      if (this.codemessage > 0) {
        setTimeout(function() {
          _this.codemessage--;
          _this.timer();
        }, 1000);
      } else {
        this.codemessage = "获取验证码";
        this.disabled = false;
      }
    },
    clickLogin() {
      this.$router.go(-1);
    },
    getVercode() {
      this.requestGetCode();
    },
    clickAlert() {
      if (this.account.length === 0) {
        Toast("请输入账号");
        return;
      }
      if (this.password.length === 0) {
        Toast("请输入密码");
        return;
      }
      if (this.repwd.length === 0) {
        Toast("请输入确认密码");
        return;
      }
      if (this.repwd !== this.password) {
        Toast("两次密码不一致");
        return;
      }
      this.requestresetPwd();
    },
  },
};
</script>

<style lang="scss" scoped>
.marginT80 {
  margin-top: 80px;
}
.marginT44 {
  margin-top: 44px;
}
.logincontent {
  width: 100%;
  height: 100%;
  background: rgba(255, 241, 235, 1);
  .loginbox {
    height: 100%;
    background: url("../../assets/images/mall/home/topbg.png");
    background-size: 100% 50%;
    background-repeat: no-repeat;
    padding: 300px 32px 32px 32px;
    .welcometxt {
      color: rgba(41, 33, 29, 1);
      font-size: 48px;
      font-weight: 700;
      font-family: "PingFang SC";
      text-align: center;
      line-height: 60px;
    }
    .inputbox {
      width: 686px;
      border-radius: 24px;
      opacity: 1;
      background: white;
      ::v-deep .van-cell {
        border-radius: 24px;
        background-color: transparent;
      }
      .verode {
        font-size: 24px;
        font-weight: 400;
        font-family: "PingFang SC";
        text-align: left;
        color: rgba(255, 114, 32, 1);
      }
    }
    .btn {
      margin-top: 72px;
      width: 686px;
      height: 96px;
      border-radius: 24px;
      opacity: 1;
      background: linear-gradient(
        90deg,
        rgba(255, 171, 74, 1) 0%,
        rgba(255, 128, 42, 1) 76%,
        rgba(255, 114, 32, 1) 100%
      );
      color: rgba(255, 255, 255, 1);
      font-size: 32px;
      font-weight: 700;
      font-family: "PingFang SC";
      text-align: center;
      line-height: 96px;
    }
    .forget {
      margin-top: 64px;
      color: rgba(255, 114, 32, 1);
      font-size: 28px;
      font-weight: 400;
      font-family: "PingFang SC";
      text-align: center;
      line-height: 40px;
    }
  }
}
</style>
